<template>
  <main class="delivery container">
    <div class="delivery__inner container-inner">
      <h1 class="delivery__title h3">{{ $t('Інформація про доставку') }}</h1>

      <div class="contacts-info">
        <div class="contacts-info__item">
          <BaseSvg
              name="ico-clock"
              width="2rem"
              height="2rem"
              class="contacts-info__item-icon"
          />
          <span class="contacts-info__name">
            <strong>{{ $t('Доставка працює щодня') }}:</strong>
            <span class="contacts-info__value">
              {{ $t('з') }} {{ $get(settings, 'orders.address_delivery_time.start') }} {{ $t('до') }}
              {{ $get(settings, 'orders.address_delivery_time.end') }};
            </span>
          </span>
        </div>
        <div class="contacts-info__item">
          <BaseSvg
              name="ico-buy"
              width="2rem"
              height="2rem"
              class="contacts-info__item-icon"
          />
          <span class="contacts-info__name">
            <strong>{{ $t('Мінімальна сума замовлення') }}:</strong>
            <span class="contacts-info__value">
              {{ $get(defaultCity, 'min_order_price', 0) | formatPrice }}
              {{ $t('грн') }};
            </span>
          </span>
        </div>
        <div class="contacts-info__item">
          <BaseSvg
              name="ico-delivery-moto"
              width="2rem"
              height="2rem"
              class="contacts-info__item-icon"
          />
          <span class="contacts-info__name">
            <strong>{{ $t('Вартість доставки') }}:</strong>
            <span class="contacts-info__value text-uppercase">
              {{ $t('безкоштовна назавжди') }};
            </span>
          </span>
        </div>
        <div class="contacts-info__item">
          <div class="">
            {{ $t('delivery_text_1') }}
            <br>
            {{ $t('delivery_text_2') }}
          </div>
        </div>
        <div class="contacts-info__item"
        >
          <div class="contacts-info__name">
            <div class="contacts-info__value">
              <span class="contacts-info__description">
                {{ $t('Зона 1') }} — <strong>{{ $get(settings, 'giftedproducts.zone_1_time') }} {{
                  $t('хв')
                }};</strong>
              </span>
            </div>
            <div class="contacts-info__value">
              <span class="contacts-info__description">
                {{ $t('Зона 2') }} — <strong>{{ $get(settings, 'giftedproducts.zone_2_time') }} {{
                  $t('хв')
                }};</strong>
              </span>
            </div>
          </div>
        </div>
        <div class="contacts-info__item">
          <div class="">
            {{ $t('delivery_text_3') }}
            <br>
            {{ $t('delivery_text_4') }}
          </div>
        </div>
      </div>

      <GmapMap v-if="$get(settings, 'giftedproducts.status', false)"
               ref="mapRef"
               class="delivery__map"
               :center="center"
               :zoom="12"
               :options="options"
      >
        <GmapPolygon v-for="(area, index) in deliveryAreas"
                     :key="index" :path="area.coordinates"
                     :options="{strokeColor: area.strokeColor, fillColor: area.fillColor}"
        />
      </GmapMap>
    </div>
  </main>
</template>

<script>
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  import {mapState} from 'vuex'
  import {MAP_STYLES} from '@/constants/map'
  import {http} from '@/axios'

  if (!Vue.prototype._mapInstaled) {
    Vue.use(VueGoogleMaps, {
      installComponents: true,
      load: {
        key: process.env.VUE_APP_MAP_KEY,
        libraries: 'places'
      }
    })
    Vue.prototype._mapInstaled = true
  }

  export default {
    name: 'Delivery',
    data() {
      return {
        center: {
          lat: 50.748010528261496,
          lng: 25.324745031036322
        },
        options: {
          styles: MAP_STYLES
        },
        deliveryAreas: []
      }
    },
    computed: {
      ...mapState('settings', ['settings']),
      ...mapState('citiesList', ['citiesList']),
      defaultCity() {
        let list = this.citiesList || null
        if (!list) return null
        let defaultCity = list.find(item => item.is_default_city)
        return defaultCity || list[0]
      },
      otherMinOrderPrice() {
        let list = this.citiesList || null
        if (!list) return 0
        let chekNull = list.findIndex(item => item.min_order_price && +item.min_order_price === 0)
        if (chekNull >= 0) {
          return 0
        }
        return list.reduce((acc, item) => {
          if (!item.is_default_city && !acc || (+item.min_order_price < acc)) {
            acc = +item.min_order_price
          }
          return acc
        }, 0)
      },
      otherMinDeliveryPrice() {
        let list = this.citiesList || null
        if (!list) return 0
        let chekNull = list.findIndex(item => item.delivery_price && +item.delivery_price === 0)
        if (chekNull >= 0) {
          return 0
        }
        return list.reduce((acc, item) => {
          if (!item.is_default_city && !acc || (+item.delivery_price < acc)) {
            acc = +item.delivery_price
          }
          return acc
        }, 0)
      },
      min_free_delivery_price() {
        return this.$get(this.defaultCity, 'min_free_delivery_price', 0)
      }
    },
    methods: {
      loadAreas() {
        http.get('/api/delivery_areas').then(({data}) => {
          this.deliveryAreas = data
        })
      }
    },
    created() {
      this.$store.dispatch('citiesList/load') //todo change to get it from settings
      this.loadAreas()
    }
  }
</script>
<style>

</style>
